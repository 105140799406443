<template>
  <div>
    <h3>Add Mover</h3>
    <div class="card" v-if="!loading">
      <div class="card-body">
        <form @submit.prevent="submit">
          <div class="form-group">
            <label>Name</label>
            <input
              type="text"
              v-model="mover.name"
              class="form-control"
              placeholder="Name"
            />
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Logo</label>
                <input
                  type="file"
                  ref="logo"
                  class="form-control"
                  placeholder="Logo"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Regions Of Operation</label>
                <v-select label="name" :options="regions" placeholder="Select Regions" :multiple="true" v-model="mover.regions" class="form-control" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Phone Number</label>
                <input
                  type="text"
                  v-model="mover.phone_number"
                  class="form-control"
                  placeholder="Phone Number"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Email</label>
                <input
                  type="text"
                  v-model="mover.email"
                  class="form-control"
                  placeholder="Email"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Description</label>
                <text-edit v-model="mover.description" placeholder="Description" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  type="checkbox"
                  v-model="mover.promoted"
                  id="is-promoted"
                  class="me-2"
                  :value="1"
                />
                <label for="is-promoted">Is Promoted</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h5>Socials</h5>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Facebook</label>
                <input
                  type="text"
                  v-model="mover.socials.facebook"
                  class="form-control"
                  placeholder="Facebook Url"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Instagram</label>
                <input
                  type="text"
                  v-model="mover.socials.instagram"
                  class="form-control"
                  placeholder="Instagram Url"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>X (Formerly Twitter)</label>
                <input
                  type="text"
                  v-model="mover.socials.twitter"
                  class="form-control"
                  placeholder="X (Formerly Twitter) Url"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>LinkedIn</label>
                <input
                  type="text"
                  v-model="mover.socials.linkedin"
                  class="form-control"
                  placeholder="LinkedIn Url"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Youtube</label>
                <input
                  type="text"
                  v-model="mover.socials.youtube"
                  class="form-control"
                  placeholder="Youtube Url"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <button class="btn btn-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      mover: { description: '', regions: [], socials: {} },
      regions: [],
      loading: false
    }
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/regions').then(response => {
        this.regions = response.data.regions
      })
    },

    submit() {
      this.loading = true
      let region_ids = this.mover.regions.map(r => { 
        return r.id 
      })
      
      let data = new FormData
      data.append('name', this.mover.name)
      data.append('regions', JSON.stringify(region_ids))
      data.append('phone_number', this.mover.phone_number)
      data.append('email', this.mover.email)
      data.append('description', this.mover.description)
      data.append('promoted', this.mover.promoted)
      data.append('socials', JSON.stringify(this.mover.socials))

      if(this.$refs.logo.files[0]) {
        data.append('logo', this.$refs.logo.files[0])
      }

      this.$axios.post('/api/v1/admin/movers', data).then(response => {
        this.loading = false
        this.$router.push({ name: 'admin.movers.show', params: { id: response.data.id }})
      })
    }
  }
};
</script>
